import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const api_url = process.env.API_URL!;

const QRCodeGenerator: React.FC = () => {
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [qrCode, setQRCode] = useState<string | null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleGenerateQRCode = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        "https://api.scanroute.xyz/api/" + "qr-codes",
        { url, name },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setQRCode(response.data.qr_code);
      setRedirectUrl(response.data.redirect_url);
    } catch (error: any) {
      alert(
        "Failed to generate QR code: " +
          (error?.response?.data?.message || error.message)
      );
    }
  };

  const handleGoToList = () => {
    navigate("/listQr");
  };

  return (
    <div>
      <h2>Generate QR Code</h2>
      <button onClick={handleGoToList} style={{ marginTop: "10px" }}>
        List of QR codes
      </button>
      <input
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter URL"
      />
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter Name"
      />
      <button onClick={handleGenerateQRCode}>Generate</button>

      {qrCode && (
        <div>
          <h3>Your QR Code</h3>
          <img src={qrCode} alt="QR Code" />
          {redirectUrl && (
            <p>
              Redirect URL: <a href={redirectUrl}>{redirectUrl}</a>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default QRCodeGenerator;
