import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Signup from "./components/Auth/Signup";
import Login from "./components/Auth/Login";
import QRCodeGenerator from "./components/QRCodeManipulation/QRCodeGenerator";
import { validateToken } from "./services/auth";
import QRCodeList from "./components/QRCodeManipulation/QRCodeList";

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const valid = await validateToken();
      setIsLoggedIn(valid);
    };
    checkLoginStatus();
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  if (isLoggedIn === null) {
    return <div>Loading...</div>; // Show loading state while checking token
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/signup"
          element={!isLoggedIn ? <Signup /> : <Navigate to="/generateQr" />}
        />
        <Route
          path="/login"
          element={
            !isLoggedIn ? (
              <Login onLoginSuccess={handleLoginSuccess} />
            ) : (
              <Navigate to="/generateQr" />
            )
          }
        />
        <Route
          path="/generateQr"
          element={isLoggedIn ? <QRCodeGenerator /> : <Navigate to="/login" />}
        />
        <Route
          path="/listQr"
          element={isLoggedIn ? <QRCodeList /> : <Navigate to="/login" />}
        />
        <Route
          path="*"
          element={
            isLoggedIn ? (
              <Navigate to="/generateQr" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
