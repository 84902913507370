import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const QRCodeList: React.FC = () => {
  const [qrCodes, setQRCodes] = useState<any[]>([]);
  const [editQR, setEditQR] = useState<{
    id: string;
    name: string;
    url: string;
  } | null>(null);
  const [newUrl, setNewUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQRCodes = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          "https://api.scanroute.xyz/api/getQRCodes",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setQRCodes(response.data);
      } catch (error: any) {
        alert(
          "Failed to fetch QR codes: " +
            (error?.response?.data?.message || error.message)
        );
      }
    };

    fetchQRCodes();
  }, []);

  const handleEditQRCode = (qr: { id: string; name: string; url: string }) => {
    setEditQR(qr);
    setNewUrl(qr.url);
  };

  const handleSaveQRCode = async () => {
    if (!editQR) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      await axios.put(
        "https://api.scanroute.xyz/api/editQRCode",
        { id: editQR.id, url: newUrl },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("QR Code updated successfully!");
      setQRCodes((prev) =>
        prev.map((qr) => (qr.id === editQR.id ? { ...qr, url: newUrl } : qr))
      );
      setEditQR(null);
    } catch (error: any) {
      alert(
        "Failed to edit QR code: " +
          (error?.response?.data?.message || error.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEditQR(null);
    setNewUrl("");
  };

  const handleGoToGenerate = () => {
    navigate("/generateQr");
  };

  return (
    <div>
      <h2>QR Code List</h2>
      <button onClick={handleGoToGenerate} style={{ marginTop: "10px" }}>
        Generate QR code
      </button>
      <ul>
        {qrCodes.map((qr) => (
          <li key={qr.id} style={{ marginBottom: "20px" }}>
            <div>
              <strong>Name:</strong> {qr.name}
            </div>
            <div>
              <strong>URL:</strong> {qr.url}
            </div>
            <div>
              <strong>Redirect URL:</strong> {qr.redirect_url}
            </div>
            <div>
              <img
                src={qr.qr_code}
                alt={`QR Code for ${qr.name}`}
                style={{ width: "100px" }}
              />
            </div>
            <button onClick={() => handleEditQRCode(qr)}>Edit</button>
          </li>
        ))}
      </ul>

      {editQR && (
        <div
          style={{
            border: "1px solid black",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <h3>Edit QR Code</h3>
          <div>
            <strong>Name:</strong> {editQR.name}
          </div>
          <input
            value={newUrl}
            onChange={(e) => setNewUrl(e.target.value)}
            placeholder="Enter new URL"
          />
          <div style={{ marginTop: "10px" }}>
            <button onClick={handleSaveQRCode} disabled={loading}>
              {loading ? "Saving..." : "Save"}
            </button>
            <button onClick={handleCancelEdit} style={{ marginLeft: "10px" }}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QRCodeList;
